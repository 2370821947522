@import './hamburgers/hamburgers.scss';

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

@font-face {
  font-family: 'HeeboBlack';
  src: url('./fonts/Heebo-Black.woff2') format('woff2'),
    url('./fonts/Heebo-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeeboMedium';
  src: url('./fonts/Heebo-Medium.woff2') format('woff2'),
    url('./fonts/Heebo-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeeboThin';
  src: url('./fonts/Heebo-Thin.woff2') format('woff2'),
    url('./fonts/Heebo-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeeboBold';
  src: url('./fonts/Heebo-Bold.woff2') format('woff2'),
    url('./fonts/Heebo-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeeboLight';
  src: url('./fonts/Heebo-Light.woff2') format('woff2'),
    url('./fonts/Heebo-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeeboExtraBold';
  src: url('./fonts/Heebo-ExtraBold.woff2') format('woff2'),
    url('./fonts/Heebo-ExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeeboRegular';
  src: url('./fonts/Heebo-Regular.woff2') format('woff2'),
    url('./fonts/Heebo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'HeeboRegular', sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  margin: 0;

  &.body {

    &.no-scroll {
      overflow: hidden;
      position: relative;

      @media (min-width: 1024px) {
        overflow-y: initial;
      }
    }
  }
}

h1, h2, h3, h4, h5, h6, p, a {
  margin: 0;
}

a {
  text-decoration: none;
  color: #0454E0;
}

.o-header {
  background-image: url('images/header-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 59.8rem;

  &--platform {
    background-image: none;
  }

  &--advertisement {
    @media(max-width: 1024px) {
      height: auto;
      padding-bottom: 6.0rem;
    }
  }

  &--sponsored-funds {
    height: auto;
    @media(max-width: 1024px) {
      height: auto;
      padding-bottom: 6.0rem;
    }
  }

  &--leadership {
    height: auto;
    background-image: none;
  }
}

.o-container {
  width: 100%;
  max-width: 144.0rem;
  margin: 0 auto;

  @media(max-width: 1440px) {
    padding: 0 3.2rem;
  }

  @media(max-width: 768px) {
    padding: 0 1.6rem;
  }
}

.c-logo {
  width: 21.0rem;
  height: 3.0rem;

  @media(max-width: 1024px) {
    width: 14.0rem;
    height: 2.0rem;
  }

  &--footer {
    width: 21.0rem;
    height: 3.0rem;
  }
}

.c-icon {
  width: 1.6rem;
  height: 1.6rem;

  &--chevron {
    width: 1.4rem;
    height: 0.7rem;
    fill: currentColor;
  }

  &--brands {
    width: 5.0rem;
    height: 5.0rem;
  }
}

.c-nav {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 3.2rem 0;
  margin: 0;

  &--subnav {
    padding: 0;

    .c-nav__item {

      &:not(:last-child) {
        margin-right: 3.0rem;

        @media (max-width: 1024px) {
          margin-right: 0;
        }
      }

      @media (max-width: 1024px) {
        display: initial;
        margin-top: 1.6rem;
      }

      &:last-child {
        @media (min-width: 1024px) {
          display: block;
        }
      }
    }

    @media (max-width: 1024px) {
      display: none;
      flex-direction: column;
      align-items: initial;
      background-color: #FFFFFF;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 20;
      padding: 10.0rem 3.2rem;

      &.is-show {
        display: flex;
      }
    }

    @media (max-width: 768px) {
      padding: 10.0rem 1.6rem;
    }

    .c-nav__link {
      @media (max-width:1024px) {
        font-size: 2.8rem;
        padding-left: 2.4rem;
      }
    }
  }

  &__item {
    display: inline-flex;
    margin: 0;

    &:first-child, &:last-child {
      z-index: 30;
    }

    &--right {
      margin-left: auto;
    }

    &--dropdown {
      position: relative;
      flex-direction: column;

      &.active {
        .c-nav__link > .c-icon--chevron {
          transform: rotate(180deg);
        }

        .c-list--dropdown {
          display: block;
        }
      }

      .c-list {
        &--dropdown {
          display: none;
          box-shadow: 1.0rem 2.0rem 3.0rem 0 rgba(0, 0, 0, 0.2);
          background-color: #FFFFFF;
          position: absolute;
          top: 4.8rem;
          right: 0;
          z-index: 10;
          padding: 3.2rem 4.0rem;
          width: 32.1rem;

          @media (max-width: 1024px) {
            position: static;
            box-shadow: none;
            background-color: initial;
            z-index: initial;
            padding: 0 2.4rem;
            width: auto;
          }
        }

        &__item {
          &:not(:last-child) {
            margin: 1.6rem 0;
          }
        }

        &__item-link {
          display: flex;
          align-items: center;
          font-size: 2.0rem;
          font-family: 'HeeboRegular', sans-serif;
          color: #000000;

          @media (max-width: 1024px) {
            font-size: 1.6rem;
          }

          .c-icon--brands {
            width: 3.0rem;
            height: 3.0rem;
            margin-right: 1.6rem;

            @media (max-width: 1024px) {
              display: none;
            }
          }
        }
      }
    }

    &:not(:first-child) {
      .c-nav__link:hover::before {
        content: "";
        width: 100%;
        height: 0.4rem;
        background-color: #0454E0;
        position: absolute;
        bottom: -1.0rem;
        left: 0;

        @media (max-width:1024px) {
          position: absolute;
          left: 0;
          bottom: auto;
          width: 1.0rem;
          height: 1.0rem;
          background-color: #0454E0;
        }
      }
    }

    &:last-child {
      @media (min-width: 1025px) {
        display: none;
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 2.0rem;
    font-family: 'HeeboMedium', sans-serif;
    color: #1E2A45;
    margin: 0;

    &--active::before {
      content: "";
      width: 100%;
      height: 0.4rem;
      background-color: #0454E0;
      position: absolute;
      bottom: -1.0rem;
      left: 0;

      @media (max-width:1024px) {
        position: absolute;
        left: 0;
        bottom: auto;
        width: 1.0rem;
        height: 1.0rem;
        background-color: #0454E0;
      }
    }

    .c-icon {
      margin-left: 1.0rem;
    }
  }
}

.hamburger {
  &--squeeze {
    .hamburger-inner {
      width: 1.6rem;
    }

    &.is-active {

      .hamburger-inner {
        width: inherit;
      }
    }
  }
}

.o-wrapper {
  &--services-intro {
    background-color: #03218D;
  }

  &--ad-products {
    background-color: #03218D;
  }
}

.o-grid {
  display: grid;

  &--hero {
    position: relative;
    grid-template-columns: 1fr 1fr;
    gap: 3.2rem;
    padding: 0 9.0rem;

    @media(max-width: 1440px) {
      padding: 0;
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      padding: 0 4.0rem;
    }

    @media(max-width: 768px) {
      padding: 0;
    }

    h1 {
      font-size: 2.8rem;
      font-family: 'HeeboMedium', sans-serif;
      font-weight: normal;
      color: #2A2A2A;
      max-width: 50.0rem;
      margin-top: 14.0rem;

      @media(max-width: 1440px) {
        margin-top: 10.0rem;
      }

      @media(max-width: 1024px) {
        margin-top: 6.0rem;
        max-width: 60.0rem;
      }

      @media(max-width: 768px) {
        font-size: 2.4rem;
      }
    }
  }

  &--hero-company {
    img {
      width: 66.8rem;
      height: 66.1rem;
      object-fit: contain;
      object-position: top;
      margin-top: 3.2rem;

      @media(max-width: 1440px) {
        width: 100%;
      }

      @media(max-width: 1024px) {
        width: 66.8rem;
        height: 66.1rem;
        max-width: 100%;
      }

      @media(max-width: 768px) {
        width: auto;
        height: auto;
        max-width: 100%;
        margin-top: 0;
      }
    }
  }

  &--hero-platform {
    h1 {
      font-size: 5.0rem;
      font-family: 'HeeboBold', sans-serif;
      line-height: 1.25;
      font-weight: normal;
      color: #2A2A2A;
      margin-top: 14.0rem;

      @media(max-width: 1440px) {
        margin-top: 10.0rem;
      }

      @media(max-width: 1024px) {
        text-align: center;
        margin-top: 6.0rem;
        max-width: none;
      }

      @media(max-width: 768px) {
        font-size: 2.8rem;
        text-align: left;
      }
    }

    img {
      width: 72.7rem;
      height: 49.5rem;
      object-fit: contain;
      object-position: top;
      margin-top: 3.2rem;

      @media(max-width: 1440px) {
        width: 100%;
      }

      @media(max-width: 1024px) {
        width: 72.7rem;
        height: 49.5rem;
        max-width: 100%;
      }

      @media(max-width: 768px) {
        width: auto;
        height: auto;
        max-width: 100%;
        margin-top: 0;
      }
    }
  }

  &--hero-advertisement {
    margin-top: 6.4rem;
    grid-template-columns: 1fr;

    @media(max-width: 1024px) {
      margin-top: 2.4rem;
    }

    h1 {
      font-size: 5.0rem;
      font-family: 'HeeboBold', sans-serif;
      line-height: 1.25;
      font-weight: normal;
      color: #2A2A2A;
      margin-top: 1.6rem;

      @media(max-width: 768px) {
        font-size: 2.8rem;
        margin-top: 1.0rem;
      }
    }

    span {
      font-size: 2.0rem;
      color: #0454E0;

      @media(max-width: 768px) {
        font-size: 1.4rem;
      }
    }

    div {
      margin-top: 4.0rem;
      column-count: 2;
      border-left: 0.5rem solid #0454E0;
      padding: 0 4.8rem;

      @media(max-width: 1024px) {
        column-count: 1;

        p + p {
          margin-top: 1.6rem;
        }
      }

      @media(max-width: 768px) {
        padding: 0 1.8rem;

        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  
  &--hero-sponsored-funds {
    padding: 4rem 9rem 6rem;
    grid-template-columns: 1fr;

    @media(max-width: 1024px) {
      padding: 0 1.8rem;
    }

    h1 {
      font-size: 5.0rem;
      font-family: 'HeeboBold', sans-serif;
      line-height: 1.25;
      font-weight: normal;
      color: #2A2A2A;
      margin: 3rem auto 1.8rem;
      width: 100%;
      max-width: 100%;

      @media(max-width: 768px) {
        font-size: 2.8rem;
        margin-top: 1.0rem;
      }
    }
  }

  &--brands {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3.2rem;
    margin-top: 15.0rem;
    padding: 0 9.0rem;

    @media (max-width:1440px) {
      padding: 0 3.0rem;
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      margin-top: 45.0rem;
      padding: 0 7.0rem;
    }

    @media(max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 1.6rem;
      margin-top: 50.0rem;

      &.o-container {
        padding: 0;
      }
    }

    @media(max-width: 740px) {
      margin-top: 40.0rem;
    }

    @media(max-width: 540px) {
      margin-top: 25.0rem;
    }

    h1 {
      font-size: 5.0rem;
      font-family: 'HeeboBold', sans-serif;
      font-weight: normal;
      color: #2A2A2A;

      @media(max-width: 768px) {
        font-size: 2.8rem;
        padding: 0 1.6rem;
      }
    }

    .c-rectangle {
      margin-top: 2.4rem;

      @media(max-width: 768px) {
        display: none;
      }
    }

    .c-card:first-child {
      background-color: transparent;
      padding: 0;
    }
  }

  &--fbrands {
    grid-template-columns: 1fr;
    justify-content: center;
    margin-top: 10.0rem;

    h1 {
      text-align: center;
      font-size: 4.0rem;
      font-family: 'HeeboBold', sans-serif;
      font-weight: normal;
      color: #1E2A45;
      max-width: 65.1rem;
      margin: 0 auto;

      @media(max-width: 768px) {
        font-size: 2.4rem;
      }
    }
  }

  &--services-intro {
    grid-template-columns: auto 1fr;
    gap: 3.2rem;
    padding: 10.0rem 9.0rem;

    @media(max-width: 1440px) {
      padding: 10.0rem 3.2rem;
    }

    @media(max-width: 1024px) {
      padding: 28.0rem 7.0rem 10rem 7.0rem;
    }

    @media(max-width: 768px) {
      padding: 20.0rem 1.6rem 10rem 1.6rem;
    }

    @media(max-width: 660px) {
      padding: 10.0rem 1.6rem 10rem 1.6rem;
    }

    @media(max-width: 560px) {
      padding: 5.0rem 1.6rem;
    }

    p {
      font-size: 2.8rem;
      color: #FFFFFF;

      @media(max-width: 1024px) {
        font-size: 2.4rem;
      }

      @media(max-width: 768px) {
        font-size: 1.6rem;
      }
    }
  }

  &--services {
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    flex-wrap: wrap;
    padding: 6.4rem 9.0rem;

    @media(max-width: 1440px) {
      padding: 6.4rem 3.2rem;
    }

    @media(max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 6.4rem 7.0rem;
    }

    @media(max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 3.4rem 1.6rem;
    }

    .c-card {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      position: relative;
      background-color: transparent;
      border-bottom: 1px solid #6F91A7;
      padding: 3.2rem;

      &:last-child {
        &::after {
          content: initial !important;
        }
      }

      &:nth-last-child(-n+2) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media(max-width: 1024px) {
        flex: initial;
        max-width: initial;

        &:nth-of-type(odd)::after {
          content: "";
          background-color: #6F91A7;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 0.1rem;
          height: 85%;
        }

        &:last-child {
          grid-area: 3 / 1 / 4 / 3;
        }

        &:nth-last-child(-n+2) {
          flex: none;
          max-width: none;
        }
      }

      @media(max-width: 768px) {
        padding: 3.2rem 0;

        &:nth-of-type(1) {
          padding-top: 0;
        }

        &::after {
          content: initial !important;
        }

        &:last-child {
          grid-area: initial;
        }
      }

      @media(min-width: 1024px) {
        &:not(:nth-of-type(3n))::after {
          content: "";
          background-color: #6F91A7;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 0.1rem;
          height: 85%;
        }
      }
    }
  }

  &--stacks {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 3.2rem;
    padding: 0 9.0rem 9.4rem 9.0rem;

    > section:first-child {
      h1, p {
        max-width: 57.0rem;
        margin-left: auto;
        margin-right: 10.0rem;
      }
    }

    @media(max-width: 1440px) {
      padding: 0 3.2rem 6.4rem 3.2rem;
      max-width: initial;
      margin: 0;

      > section:first-child {
        h1, p {
          margin-left: initial;
          margin-right: 0;
        }
      }
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      padding: 0 7.0rem 6.4rem 7.0rem;
    }

    @media(max-width: 768px) {
      padding: 0 1.6rem 6.4rem 1.6rem;
    }

    h1 {
      font-size: 5.0rem;
      font-family: 'HeeboBlack', sans-serif;
      font-weight: normal;
      color: #2A2A2A;

      @media(max-width: 768px) {
        font-family: 'HeeboBold', sans-serif;
        font-size: 2.4rem;
      }
    }

    p {
      max-width: 35.0rem;
      color: #000000;
      margin-top: 1.6rem;

      @media(max-width: 768px) {
        font-size: 1.6rem;
      }
    }
  }

  &--techstacks {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: end;
    gap: 3.2rem;

    @media(max-width: 1024px) {
      max-width: 53.0rem;
    }

    @media(max-width: 768px) {
      grid-template-columns: repeat(4, 70px);
      gap: 1.6rem;
    }

    .c-card {
      background-color: transparent;
      padding: 0;

      &__heading {
        font-family: 'HeeboBold', sans-serif;
        white-space: nowrap;
        font-size: 2.0rem;
        font-weight: normal;
        color: #000000;
      }

      img {
        display: block;
        margin-top: 1.6rem;

        @media(max-width: 768px) {
          width: 7.0rem;
          height: 7.0rem;
        }
      }
    }
  }

  &--prof-audience {
    padding: 6.4rem 9.0rem;

    @media(max-width: 1440px) {
      padding: 3.2rem 3.4rem;
    }

    @media(max-width: 1024px) {
      padding: 3.2rem 6.4rem;
    }

    @media(max-width: 768px) {
      padding: 6.4rem 1.6rem
    }

    h1 {
      font-size: 4.0rem;
      font-family: "HeeboBold", sans-serif;
      font-weight: normal;
      color: #2A2A2A;

      @media(max-width: 768px) {
        font-size: 2.4rem;
      }
    }

    > section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 3.2rem;
      margin-top: 2.4rem;

      @media(max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }

      @media(max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 1.6rem;
      }

      .c-card {
        &__heading {
          font-size: 5.0rem;
          color: #0454E0;
          margin-top: 0;

          @media(max-width: 768px) {
            font-size: 3.0rem;
          }
        }

        &__body {
          font-size: 2.0rem;
          margin: 0;

          @media(max-width: 768px) {
            font-size: 1.6rem;
          }
        }

        @media(max-width: 768px) {
          padding: 2.4rem;
        }
      }
    }
  }

  &--indi-audience {
    padding: 0 9.0rem 6.4rem 9.0rem;

    @media(max-width: 1440px) {
      padding: 3.2rem 3.4rem;
    }

    @media(max-width: 1024px) {
      padding: 3.2rem 6.4rem;
    }

    @media(max-width: 768px) {
      padding: 0 1.6rem 6.4rem 1.6rem;
    }

    h1 {
      font-size: 4.0rem;
      font-family: "HeeboBold", sans-serif;
      font-weight: normal;
      color: #2A2A2A;

      @media(max-width: 768px) {
        font-size: 2.4rem;
      }
    }

    > section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 3.2rem;
      margin-top: 2.4rem;

      @media(max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }

      @media(max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 1.6rem;
      }

      .c-card {
        &__heading {
          font-size: 5.0rem;
          color: #0454E0;
          margin-top: 0;

          @media(max-width: 768px) {
            font-size: 3.0rem;
          }
        }

        &__body {
          font-size: 2.0rem;
          margin: 0;

          @media(max-width: 768px) {
            font-size: 1.6rem;
          }
        }

        @media(max-width: 768px) {
          padding: 2.4rem;
        }
      }
    }
  }

  &--ad-products {
    grid-template-columns: auto 1fr;
    gap: 10.0rem;
    padding: 10.0rem 9.0rem;

    @media(max-width: 1440px) {
      grid-template-columns: 1fr;
      gap: 1.6rem;
      padding: 10.0rem 3.2rem;
    }

    @media(max-width: 1024px) {
      padding: 10.0rem 7.0rem;
    }

    @media(max-width: 768px) {
      padding: 6.4rem 1.6rem;
    }

    h1 {
      font-size: 5.0rem;
      font-family: "HeeboBold", sans-serif;
      font-weight: normal;
      color: #FFFFFF;

      @media(max-width: 1440px) {
        font-size: 4.0rem;
      }

      @media(max-width: 768px) {
        font-size: 2.4rem;
      }
    }
  }

  &--ad-products-gallery {
    grid-template-columns: 1fr 1fr;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .c-card {
      position: relative;
      background-color: transparent;
      border-bottom: 1px solid #6F91A7;
      padding: 3.2rem;

      &:nth-of-type(odd)::after {
        content: "";
        background-color: #6F91A7;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0.1rem;
        height: 85%;
      }

      @media(max-width: 768px) {
        padding: 3.2rem 0;

        &:nth-of-type(1) {
          padding-top: 0;
        }

        &::after {
          content: initial !important;
        }
      }

      &__heading {
        margin-top: 0;
        color: #FFFFFF;

        @media(max-width: 768px) {
          font-size: 2.0rem;
        }
      }

      &__body {
        color: #FFFFFF;
      }
    }

    .c-button {
      width: 34.0rem;
      margin-top: 4.8rem;
      grid-area: 4 / 1 / 5 / 3;
      margin-left: auto;

      @media(max-width: 1440px) {
        font-size: 1.6rem;
        margin: 4.8rem auto;
      }

      @media(max-width: 768px) {
        width: 100%;
        font-size: 1.6rem;
        padding: 2.4rem;
        grid-area: initial;
      }
    }
  }

  &--leaders {
    grid-template-columns: 1fr;
    gap: 3.2rem;
    padding: 4.0rem 9.0rem;

    @media (max-width:1440px) {
      padding: 3.0rem;
    }

    @media(max-width: 1024px) {
      padding: 4.0rem 7.0rem;
    }

    @media(max-width: 768px) {
      padding: 0 1.6rem;
      gap: 1.6rem;
    }

    h1 {
      font-size: 5.0rem;
      font-family: 'HeeboBold', sans-serif;
      font-weight: normal;
      color: #2A2A2A;

      @media(max-width: 768px) {
        font-size: 2.8rem;
      }
    }

    .c-rectangle {
      margin-top: 1.6rem;
    }
  }

  &--leaders-gallery {
    display: grid;
    grid-template-columns: 1fr;
    padding: 4.0rem 9.0rem;

    @media (max-width:1440px) {
      padding: 3.0rem;
    }

    @media(max-width: 1024px) {
      padding: 4.0rem 7.0rem;
    }

    @media(max-width: 768px) {
      padding: 4.0rem 1.6rem;
      gap: 1.6rem;
    }

    .c-card {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 4.0rem;
      background-color: transparent;
      border-top: 1px solid #6F91A7;
      padding: 6.4rem 0;

      @media(max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 2.0rem;
        padding: 3.4rem 0;
      }

      &__heading {
        margin-top: 0;

        &--desktop, &--desktop + span {
          @media(max-width: 1024px) {
            display: none;
          }
        }

        &--mobile, &--mobile + span {
          @media(min-width: 1024px) {
            display: none;
          }
        }

        &--mobile {
          @media(max-width: 768px) {
            font-size: 2.4rem;
          }
        }
      }

      &__body {
        @media(max-width: 1024px) {
          margin-top: 0;
        }
      }

      img {
        display: block;
        width: 30.0rem;
        height: 30.0rem;
        object-fit: cover;

        @media(max-width: 1024px) {
          width: 21.0rem;
          height: 21.0rem;
          margin-top: 1.6rem;
        }

        @media(max-width: 768px) {
          height: auto;
          width: auto;
          max-width: 100%;
        }
      }

      span {
        font-size: 2.0rem;
        color: #0454E0;

        @media(max-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }
  }

  &--footer {
    padding: 0 9.0rem;

    @media (max-width: 768px) {
      padding: 0 1.6rem;
    }

    h1 {
      text-align: center;
      font-size: 2.8rem;
      font-family: 'HeeboBold', sans-serif;
      font-weight: normal;
      color: #FFFFFF;
      max-width: 62.8rem;
      margin: 8.4rem auto 0 auto;

      @media(max-width: 1024px) {
        font-size: 2.4rem;
        font-family: 'HeeboMedium', sans-serif;
        font-weight: normal;
      }
    }

    p {
      font-size: 1.6rem;
      color: #FFFFFF;
      text-align: center;
      margin: 1.6rem 0 3.2rem 0;

      @media(max-width: 768px) {
        margin: 2.4rem 0 3.2rem 0;
        text-align: left;
      }
    }

    .c-seperator {
      margin: 6.4rem 0 3.2rem 0;

      @media(max-width: 768px) {
        margin: 4.4rem 0 0 0;
      }
    }

    .c-button {
      width: auto;
      display: inline-block;
      margin: 0 auto;
      margin-top: 3.2rem;
      padding: 3.2rem 6.4rem;

      @media(max-width: 1024px) {
        width: 32.0rem;
        font-size: 1.6rem;
        font-family: 'HeeboMedium', sans-serif;
        font-weight: normal;
        padding: 2.4rem;
      }

      @media(max-width: 768px) {
        width: 100%;
      }
    }

    .c-nav--footer {
      align-items: flex-start;

      @media(max-width: 1140px) {
        flex-wrap: wrap;
      }

      @media(max-width: 768px) {
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0;
      }

      .c-nav__item {
        @media(max-width: 1140px) {
          &:first-child {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 3.2rem;
          }
        }

        @media(max-width:768px) {
          margin-right: 0 !important;
          margin-top: 2.4rem;

          &:first-child {
            margin-bottom: 0;
          }
        }

        &:not(:last-child) {
          margin-right: 8.4rem;

          @media(max-width: 1024px) {
            margin-right: 3.2rem;
          }
        }

        &:first-child {
          a {
            margin-top: 0;
          }
        }

        &:last-child {
          display: block;
        }

        &--right {
          @media(max-width: 1440px) {
            margin-left: initial;
          }
        }
      }

      h3 {
        font-family: 'HeeboBold', sans-serif;
        font-size: 1.6rem;
        color: #FFFFFF;
      }

      p, a, address {
        font-size: 1.6rem;
        font-style: initial;
        text-align: left;
        color: #FFFFFF;
        margin-top: 1.6rem;
        margin-bottom: 0;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.c-seperator {
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  height: 0.1rem;
}

.c-button {
  font-family: 'HeeboMedium';
  display: inline-block;
  background-color: #0454E0;
  color: #FFFFFF;
  font-size: 2.4rem;
  line-height: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid transparent;
  max-width: 100%;
  appearance: none;
  cursor: pointer;
  user-select: none;
  padding: 3.2rem;

  &:hover {
    background-color: #03218D;
  }
}

.c-card {
  background-color: #F9FAFC;
  padding: 3.6rem;

  @media (max-width:768px) {
    padding: 2.0rem 1.6rem;
  }

  &__heading {
    font-size: 2.8rem;
    font-family: 'HeeboBold', sans-serif;
    font-weight: normal;
    word-break: break-word;
    color: #2A2A2A;
    margin-top: 3.2rem;

    @media(max-width: 768px) {
      margin-top: 1.6rem;
      font-size: 2.4rem;
    }
  }

  &__body {
    font-size: 2.0rem;
    font-family: 'HeeboRegular', sans-serif;
    color: #000000;
    margin-top: 1.6rem;

    @media(max-width: 768px) {
      font-size: 1.6rem;
    }

    a {
      text-decoration: underline;
    }
  }
}

.c-rectangle {
  display: block;
  width: 4.0rem;
  height: 4.0rem;
  background-color: #0454E0;
}

.c-circle {
  display: block;
  width: 6.0rem;
  height: 6.0rem;
  background-color: #E3EDEE;
  border-radius: 50%;
}

.c-badge {
  display: inline-block;
  font-size: 1.6rem;
  font-family: 'HeeboBold', sans-serif;
  background-color: rgba(241, 174, 78, .4);
  color: #1E2A45;
  padding: 0.6rem 1.2rem;
  margin-top: 1.6rem;

  @media(max-width: 768px) {
    font-size: 1.4rem;
  }
}

.c-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &--fbrands {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 114.9rem;
    margin: 6.4rem auto 9.4rem auto;

    .c-list__item {
      margin: 1.6rem;

      @media(max-width: 1440px) {
        margin: 1.2rem;
      }

      @media(max-width: 1024px) {
        margin: 0.8rem;
      }
    }
  }

  .c-list__item {
    img {
      max-width: 100%;
    }
  }
}

footer {
  background-image: url('images/footer-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
